/* eslint-disable */
import camelCase from "lodash/camelCase";
import isArray from "lodash/isArray";
import isDate from "lodash/isDate";
import isFunction from "lodash/isFunction";
import isObject from "lodash/isObject";
import mapKeys from "lodash/mapKeys";
import mapValues from "lodash/mapValues";

const mapKeysDeep = (obj: any, callback: (key: string) => string): any => {
  if (isArray(obj)) {
    return obj.map(innerObj => mapKeysDeep(innerObj, callback));
  } else if (isObject(obj) && !isDate(obj) && !isFunction(obj)) {
    return mapValues(
      mapKeys(obj, (_, key) => callback(key)),
      val => mapKeysDeep(val, callback)
    );
  }

  return obj;
};

export const camelcaseObjectDeep = (value: any): any =>
  mapKeysDeep(value, camelCase);
