import styled from "@emotion/styled";
import ReactDOMServer from "react-dom/server";

// Library hooks
import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSWRInfinite } from "swr";
import { useIntersection } from "react-use";

// Novel
import { query_recursive_types as RpcRecursiveTypes } from "../infra/api/rpc/api";

// Usecase
import { NovelUseCase } from "../usecases/novelUseCase";

// Hooks
import { useAuth } from "../hooks/useAuth";
import { useLayoutUI } from "../hooks/useLayoutUI";
import { useChatStudioRedirect } from "../hooks/useChatStudioRedirect";
import { useAppParameters } from "../hooks/useAppParameters";
import { useMustAcknowledgeGuidelines } from "../hooks/useMustAcknowledgeGuidelines";

// Components
import { AppHeader } from "../components/common/header/appHeader";
import { ActivityModal } from "../components/common/activityModal";
import { ImageHasBeenReplacedModal } from "../components/common/imageHasBeenReplacedModal";
import { MustAcknowledgeModal } from "../components/common/mustAcknowledgeModal";
import { NoResults } from "../components/common/noResults";
import { NovelCell } from "../components/novel/novelCell";
import { Image } from "../components/ui/image";
import { Color, FontSize, Media } from "../components/styles/enums";
import { Button, ButtonVariant } from "../components/ui/button";
import { StoryTypeModal } from "../components/common/storyTypeModal";

import { PullToReload } from "../libs/pullToReload";
import loadingIcon from "../assets/spinner.svg";
import pullToRefreshArrow from "../assets/pull_to_refresh_down.png";
import { logError } from "../utils/utils";
import { useGuidelinesModalForNewUser } from "../hooks/useGuidelinesModalForNewUser";
import { GuidelinesModalForNewUser } from "../components/common/guidelinesModalForNewUser";
import { useGetContestsBanner } from "../hooks/useGetContestsBanner";
import { ContestBanner } from "../components/common/contestBanner";

const PageWrapper = styled.div`
  background-color: ${Color.PRIMARY};
  padding-bottom: 180px;
  max-width: 960px;
  margin: auto;
`;

const WriteStory = styled(Button)`
  position: fixed;
  left: 50%;
  margin-left: -172px;
  bottom: 53px;
  height: 40px;
  font-size: ${FontSize.SIZE_16};
  font-weight: 700;
  width: 344px;

  @media ${Media.SMALL} {
    height: 56px;
  }

  @media ${Media.SMALLEST} {
    width: 300px;
    margin-left: -150px;
  }
`;

const PullToReloadRef = styled.div`
  line-height: 80px;
  &.threshold {
    .loading {
      display: inline-block;
      margin-right: -25px;
      margin-top: -3px;
    }
    .arrow {
      transform: rotate(180deg);
    }
  }
`;

const DownArrow = styled(Image)`
  height: 15px;
  vertical-align: middle;
  margin-right: 14px;
  margin-bottom: 4px;
  transition: all 0.3s;
  transform: rotate(0deg);
`;

const LoadingInd = styled(Image)`
  display: none;
  height: 30px;
  width: 30px;
  vertical-align: middle;
`;

const PullDownSentence = styled.span`
  color: ${Color.ACCENT_500};
`;

const Content = styled.div`
  min-height: 90vh;
  height: 90vh;
  padding-bottom: 150px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
`;

const NoticeBanner = styled.div`
  span {
    width: fit-content;
    position: relative;
    &::after {
      content: "";
      width: 8px;
      height: 8px;
      border-top: solid 2px white;
      border-right: solid 2px white;
      position: absolute;
      top: 0;
      bottom: 0;
      right: -12px;
      margin: -5px 0 auto;
      transform: rotate(45deg);
    }
  }

  background: ${Color.TINT};
  height: 44px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: ${FontSize.SIZE_14};
  font-weight: 700;
  color: ${Color.WHITE};
  text-align: center;
`;

const novelUseCase = new NovelUseCase();

const LoadingSpinner = <Image src={loadingIcon} width={40} height={40} />;
const PullInnerContent = (
  <>
    <DownArrow className="arrow" src={pullToRefreshArrow} />
    <PullDownSentence>引き下げて更新</PullDownSentence>
    <LoadingInd className="loading" src={loadingIcon} />
  </>
);

export const HomeContainer: React.FC = () => {
  const { setLayout } = useLayoutUI();

  const [layoutSet, setLayoutSet] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const {
    isApp,
    defaultTags,
    clearDefaultTags,
    canUseReactNativeStudio
  } = useAppParameters();

  // show modal when default tag was set
  const [storyTypeModalIsOpen, setStoryTypeModalOpen] = useState(
    defaultTags.length > 0
  );

  const { tellerUser, isOfficialWriter } = useAuth();
  const { goToChatStudio } = useChatStudioRedirect();
  const history = useHistory();

  const writeStory = useCallback(() => {
    setStoryTypeModalOpen(true);
  }, []);

  const closeStoryTypeModal = useCallback(() => {
    setStoryTypeModalOpen(false);
    clearDefaultTags();
  }, [clearDefaultTags]);

  const toNovel = useCallback(() => {
    history.push(
      `/novel/new/episodes/new?from=home${
        defaultTags.length > 0 ? `&tags=${defaultTags.join(",")}` : ""
      }`
    );
    clearDefaultTags();
  }, [clearDefaultTags, defaultTags, history]);

  const toNewChatStory = useCallback(() => {
    goToChatStudio("/stories/new");
    clearDefaultTags();
  }, [clearDefaultTags, goToChatStudio]);

  const paginationRef = useRef<HTMLDivElement>(null);
  const pullToReloadRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const getKey = useCallback(
    (_, prevData) => {
      if (!tellerUser?.id?.value) {
        return null;
      }
      if (!prevData) {
        return ["/api/user/novels", ""];
      }
      if (prevData.cursor?.endCursor?.value) {
        return ["/api/user/novels", prevData.cursor.endCursor.value];
      }
      return null;
    }, // SWR key
    [tellerUser]
  );

  const fetcher = useCallback(
    (_key, cursor): Promise<RpcRecursiveTypes.IMySeriesCursorResponse | null> =>
      novelUseCase.fetchUserNovels(cursor),
    []
  );

  const {
    data: novelPages,
    error,
    size,
    setSize,
    isValidating,
    mutate
  } = useSWRInfinite(getKey, fetcher);

  const isLoadingFirstPage = !novelPages && !error;

  useEffect(() => {
    if (error) {
      logError(error);
    }
  }, [error]);

  useEffect(() => {
    if (
      isLoadingFirstPage ||
      (size > 0 && novelPages && !novelPages[size - 1])
    ) {
      setIsLoading(true);
    } else {
      // Delay reset loading state so we don't trigger multiple page request at almost the same time
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [isLoadingFirstPage, novelPages, size]);

  const isEmpty =
    novelPages?.[0] === null || novelPages?.[0]?.seriesList?.length === 0;
  const isLastPage = novelPages?.[size - 1]?.cursor?.endCursor?.value === "";

  const intersection = useIntersection(paginationRef, {
    root: null,
    rootMargin: "0px",
    threshold: 1
  });

  useEffect(() => {
    if (
      intersection?.intersectionRatio === 1 &&
      !isValidating &&
      !isLastPage &&
      !isLoading
    ) {
      setSize(size + 1);
    }
  }, [
    intersection?.intersectionRatio,
    isLastPage,
    isLoading,
    isValidating,
    setSize,
    size
  ]);

  const ptr = useRef<PullToReload | null>();

  useEffect(() => {
    if (contentRef.current && pullToReloadRef.current && !ptr.current) {
      // eslint-disable-next-line no-new
      ptr.current = new PullToReload({
        "loading-content": ReactDOMServer.renderToString(LoadingSpinner),
        height: 80,
        "default-inner": ReactDOMServer.renderToString(PullInnerContent),
        "default-text": "引き下げて更新",
        "threshold-reached-text": "離して更新",
        "border-height": 0,
        "font-size": "13px",
        "content-element": contentRef.current,
        "refresh-element": pullToReloadRef.current,
        threshold: 5,
        "callback-loading": () => {
          setTimeout(() => {
            mutate();
            ptr?.current?.loadingEnd();
          }, 1000);
        }
      });
    }
  }, [contentRef, mutate, pullToReloadRef]);

  useEffect(() => {
    if (!layoutSet) {
      setLayout({
        title: "自分の作品"
      });
      setLayoutSet(true);
    }
  }, [layoutSet, setLayout]);

  const {
    novelId: guidelinesAcknowledgeNeededNovelId,
    isImageReplacedModalOpen,
    openImageReplacedModal,
    closeImageReplacedModal,
    isGuidelinesAcknowledgeModalOpen,
    closeGuidelinesAcknowledgedModal
  } = useMustAcknowledgeGuidelines();

  const {
    isOpen: isOpenGuidelinesModalForNewUser,
    handleOnEnter: handleOnEnterGuidelinesModalForNewUser
  } = useGuidelinesModalForNewUser();

  const openReactNativeStudio = useCallback(() => {
    window.location.href = "teller-studio://switch-studio-version";
  }, []);

  const { contestBanner } = useGetContestsBanner();

  return (
    <div>
      {isApp ? (
        <AppHeader title="自分の作品" noBackButton moreButton={false}>
          {canUseReactNativeStudio && (
            <NoticeBanner onClick={openReactNativeStudio}>
              <span>新しいエディターをためす</span>
            </NoticeBanner>
          )}
        </AppHeader>
      ) : null}
      <PageWrapper>
        <PullToReloadRef ref={pullToReloadRef}>
          {PullInnerContent}
        </PullToReloadRef>
        <Content ref={contentRef}>
          {contestBanner && (
            <ContestBanner contestBanner={contestBanner} isApp={isApp} />
          )}
          {isEmpty ? <NoResults text="ノベルは見つかりませんでした" /> : null}
          {!novelPages ? null : (
            <>
              {novelPages?.map(novels =>
                novels?.seriesList?.map(
                  (novel: RpcRecursiveTypes.ISeriesResponse) => (
                    <NovelCell
                      key={novel.id?.value}
                      novel={novel}
                      isOfficialWriter={isOfficialWriter}
                      onGuidelinesCheckNeeded={openImageReplacedModal}
                    />
                  )
                )
              )}
            </>
          )}
          <div ref={paginationRef} />
        </Content>

        {isLoading || isValidating ? <ActivityModal /> : null}

        <WriteStory
          className="fixedButton"
          block
          variant={ButtonVariant.PILL}
          color={Color.TINT}
          onClick={writeStory}
        >
          新しい作品を書く
        </WriteStory>
        <StoryTypeModal
          open={storyTypeModalIsOpen}
          onClose={closeStoryTypeModal}
          onChatClicked={toNewChatStory}
          onNovelClicked={toNovel}
        />
        {guidelinesAcknowledgeNeededNovelId && (
          <>
            <ImageHasBeenReplacedModal
              open={isImageReplacedModalOpen}
              onClose={closeImageReplacedModal}
            />
            <MustAcknowledgeModal
              open={isGuidelinesAcknowledgeModalOpen}
              onClose={closeGuidelinesAcknowledgedModal}
            />
          </>
        )}
      </PageWrapper>
      <GuidelinesModalForNewUser
        open={isOpenGuidelinesModalForNewUser}
        onEnterButton={handleOnEnterGuidelinesModalForNewUser}
      />
    </div>
  );
};
