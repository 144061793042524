import { createClient } from "microcms-js-sdk";
import useSWR from "swr";
import { camelcaseObjectDeep } from "../utils/object";

export type ContestBanner = {
  servingUrlPc: ServingImage;
  servingUrlSp: ServingImage;
  url: string;
  description: string;
};

export type ServingImage = {
  servingUrl: string;
  width: number;
  height: number;
};

const fetchContestBanner = async (): Promise<ContestBanner> => {
  const client = createClient({
    serviceDomain: process.env.REACT_APP_MICRO_CMS_SERVICE_DOMAIN!,
    apiKey: process.env.REACT_APP_MICRO_CMS_API_KEY!
  });

  return client
    .get({
      endpoint: "web-contest-banner"
    })
    .then(
      (res): ContestBanner =>
        (camelcaseObjectDeep(res) as unknown) as ContestBanner
    );
};

export const useGetContestsBanner = (): {
  contestBanner: ContestBanner | undefined;
  isLoading: boolean;
  isError: any;
} => {
  const { data, error } = useSWR("web-contest-banner", fetchContestBanner);

  return {
    contestBanner: data,
    isLoading: !error && !data,
    isError: error
  };
};
