/* eslint-disable */
import styled from "@emotion/styled";
import { FC, useMemo } from "react";
import { resizeServingUrl } from "../../utils/imageUtils";
import { ContestBanner as ContestBannerData } from "../../hooks/useGetContestsBanner";
import { COMMUNITY_GUIDELINE_LINK } from "../../utils/constants";

const Link = styled.a`
  width: 100%;
  max-width: 1000px;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

type Props = {
  contestBanner: ContestBannerData;
  isApp: boolean;
};

export const ContestBanner: FC<Props> = ({ contestBanner, isApp }) => {
  const href = useMemo(
    (): string =>
      isApp
        ? `teller-studio://in-app-webview?url=${contestBanner.url}`
        : contestBanner.url,
    [isApp]
  );

  return (
    <Link href={href} target={isApp ? "_self" : "_blank"} rel="noreferrer">
      <div>
        <picture>
          {/* NOTE: origin uploaded image is 3x */}
          <source
            srcSet={`
          ${resizeServingUrl(
            contestBanner.servingUrlPc.servingUrl,
            1100
          )} 1100w,
          ${resizeServingUrl(
            contestBanner.servingUrlPc.servingUrl,
            1500
          )} 1500w,
          ${resizeServingUrl(
            contestBanner.servingUrlPc.servingUrl,
            2000
          )} 2000w,
          `}
            media="(min-width: 1100px)"
          />
          <source
            srcSet={`
        ${resizeServingUrl(contestBanner.servingUrlSp.servingUrl, 320)} 320w,
        ${resizeServingUrl(contestBanner.servingUrlSp.servingUrl, 480)} 480w,
        ${resizeServingUrl(contestBanner.servingUrlSp.servingUrl, 640)} 640w,
        ${resizeServingUrl(contestBanner.servingUrlSp.servingUrl, 800)} 800w,
        `}
          />
          <Image
            src={resizeServingUrl(contestBanner.servingUrlSp.servingUrl, 320)!}
            alt={contestBanner.description}
          />
        </picture>
      </div>
    </Link>
  );
};
